<template>
  <div class="list">
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <div class="choseItem"
           style="margin-top: 10px;border-radius: 6px;border: 1px solid transparent;box-shadow: 0px 2px 6px 0px rgba(110,115,126,0.1);"
           v-for="(item, index) in userList">
        <div class="list-item">
          <img @click="$router.push('/contact/detail?contactId='+item.id)" class="avater" :src="item.avatar" alt="">
          <div @click="$router.push('/contact/detail?contactId='+item.id)" class="mindel">
            <div class="item">
              <div style="display: flex;align-items: center">
                <div class="name">{{ item.name }}</div>
              </div>
              <div>
                <div class="line2">
                  {{ !item.createdAt ? '无' : item.createdAt }}
                </div>
              </div>
            </div>
          </div>
          <div class="acea-row row-middle" @click="collectFromHighsea(item.id)">
            <img class="news"
                 src="@/assets/images/customer/public_add.png">
            <div class="font-size-14-500 font-color-000">领取</div>
          </div>
        </div>
        <div class="bottom">
          <div style="width: 100%;height: 1px;color: #EEEEEE;margin: 0 20px"></div>
          <div class="acea-row row-middle row-between bottom-area">
            <div class="bottom-list">
              <div style="max-width: 200px" class="line1">客户动态：{{ item.customerTrack.content ? item.customerTrack.content : '无' }}</div>
            </div>
            <div class="font-size-12-400">{{item.customerTrack.createdAt}}</div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import {collectFromHighsea, topicSeaCustomerList} from "@/api/customer";

export default {
  name: "publicSea",
  props:['topicId'],
  data(){
    return{
      loading: false,
      finished: false,
      page: 1,
      userList: [],
    }
  },
  methods:{
    onLoad() {
      let dataLiS = {}
      dataLiS.page = this.page
      dataLiS.perPage = 10
      dataLiS.topicId = this.topicId
      topicSeaCustomerList(dataLiS).then(res => {
        console.log(res.data.data.length)
        this.userList.push(...res.data.data)
        console.log(this.userList)
        if (res.data.data.length < 10) {
          this.finished = true
          this.loading = true
        } else {
          this.page++
          this.finished = false
          this.loading = false
        }
      }).catch((err) => {
        this.loading = false
        this.finished = true
      })
    },
    collectFromHighsea(id){
      let param = []
      param.push(id);
      collectFromHighsea({
        customerIds: JSON.stringify(param)
      }).then(res => {
        this.$message.success('领取成功')
        this.page = 1
        this.userList = []
        this.onLoad()
      }).catch((err) => {

      })
    },
  }
}
</script>

<style scoped lang="less">
.list {

  .choseItem {
    border: 1px solid #1890ff;

    .bottom {

      .bottom-area {
        background: #fff;
        padding: 10px 12px;
      }
      .bottom-list {
        font-size: 12px;
        display: flex;
        align-items: baseline;
      }
    }
  }

  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 10px 12px;
    border-radius: 7px 7px 0 0;

    .mindel {
      flex: 1;
      margin-left: 10px;
    }

    .avater {
      width: 50px;
      height: 50px;
      border-radius: 5px;
    }

    .item {
      //margin-left: -87px;

      .name {
        font-size: 14px;
        color: #000000;
        font-weight: 500;
        max-width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }


      .line2 {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal;
      }

      div:nth-child(1) {
        h4 {
          font-weight: bold;
          max-width: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span {
          color: #5ec75d;
          margin-left: 2px;
        }
      }

      div:nth-child(2) {
        font-size: 12px;
        color: #6a6a6a;

        div:nth-child(1) {
          //display: flex;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
      }
    }

    .news {
      width: 34px;
      height: 27px;
    }
  }
}
</style>
