<template>
  <div class="dai-info">
    <img class="top-img" src="../../../assets/images/customer/dai_bg.png">
    <div class="dai-item">
      <div class="acea-row row-between row-middle">
        <div class="acea-row row-middle">
          <img style="width: 29px;height: 29px;margin-right: 9px" :src="itemData.contactAvatar">
          <div class="font-size-15-500 font-color-000 line1" style="max-width: 100px">{{ itemData.contactName }}</div>
          <div class="yu-tag acea-row row-center row-middle font-color-FFF font-size-12-400" v-if="type == 3 && itemData.isShow">逾期</div>
        </div>
        <div class="acea-row row-middle">
          <div v-if="itemData.isShow">
            <div @click="goDetail(itemData.customerId)" class="follow-btn acea-row row-center row-middle font-color-FFF font-size-12-400" style="z-index: 99">
              跟进
            </div>
          </div>
          <div class="font-color-E33 font-size-12-400" v-else>有待办事项需要处理！</div>
          <div class="acea-row row-middle" style="margin-left: 15px" @click="change">
             <div class="font-size-12-400 font-color-666">{{itemData.isShow?'收起':'查看'}}</div>
             <van-icon :name="itemData.isShow?'arrow-up':'arrow-down'" size="14"/>
          </div>
        </div>
      </div>
      <div v-if="itemData.isShow">
        <div class="font-size-12-400 font-color-666" style="margin-top: 12px">
          提醒时间： {{ itemData.day }} {{ itemData.time }}
        </div>
        <div class="line" style="margin: 12px 0"></div>
        <div class="font-size-12-400 font-color-333 line2" style="width: 100%">
          {{ itemData.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {updateStatus} from "@/api/homePage";

export default {
  name: "daiItem",
  props: ['item','type'],
  data(){
    return{
      itemData:{}
    }
  },
  mounted() {
    this.itemData = this.item;
    this.$set(this.itemData, 'isShow', true)
  },
  methods:{
    change(){
      console.log(this.itemData.isShow)
      this.$set(this.itemData,"isShow",!this.itemData.isShow)
    },
    goDetail(id){
      this.$router.push("/contact/detail?contactId="+id)
      // updateStatus({id: this.itemData.id}).then(res => {
      //   this.$emit("reload")
      // })
    }
  }
}
</script>

<style scoped lang="less">
.dai-info {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px rgba(22, 139, 246, 0.1);
  border-radius: 6px;
  position: relative;

  .top-img {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 0;
  }

  .dai-item {
    padding: 13px 11px;
    position: relative;
    z-index: 2;

    .follow-btn {
      width: 45px;
      height: 25px;
      background: linear-gradient(90deg, #168BF6, #3DA2FF);
      border-radius: 13px;
    }

    .yu-tag {
      width: 37px;
      height: 18px;
      background: linear-gradient(90deg, #FF5555, #EB3131);
      border-radius: 0px 6px 0px 6px;
    }
  }
}
</style>
