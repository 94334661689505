<template>
  <div>
    <van-nav-bar
        title="主题列表"
        left-arrow
        :border="false"
        @click-left="$router.back()"
    />
    <van-tabs v-model="titleActive" color="#1890ff" :line-width="20"
              title-active-color="#168BF6">
      <van-tab :name="1" title="客户">
        <div style="padding: 12px;background: #ffffff" class="acea-row row-middle row-between">
          <div class="acea-row">
            <div @click="changeStatus('')" :class="status === ''?'active font-color-BF6':'no-active font-color-666'" class="item1 font-size-12-400 acea-row row-center row-middle">全部</div>
            <div @click="changeStatus(0)" :class="status === 0?'active font-color-BF6':'no-active font-color-666'" class="item2 font-size-12-400 acea-row row-center row-middle">待沟通</div>
            <div @click="changeStatus(1)" :class="status === 1?'active font-color-BF6':'no-active font-color-666'" class="item3 font-size-12-400 acea-row row-center row-middle">需再次跟进</div>
          </div>
          <div class="acea-row row-middle" @click="$refs.search.openShowFilterPopup()">
            <van-icon size="16" name="filter-o" />
            <div class="font-size-12-400 font-color-666">筛选</div>
          </div>
        </div>
        <div class="list">
          <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
            <div class="choseItem"
                 style="margin-top: 10px;border-radius: 6px;border: 1px solid transparent;box-shadow: 0px 2px 6px 0px rgba(110,115,126,0.1);"
                 v-for="(item, index) in userList">
              <div class="list-item">
                <img @click="$router.push('/contact/detail?contactId='+item.id)" class="avater" :src="item.avatar" alt="">
                <div @click="$router.push('/contact/detail?contactId='+item.id)" class="mindel">
                  <div class="item">
                    <div style="display: flex;align-items: center">
                      <div class="name">{{ item.name }}</div>
                      <!--                      <span v-if="item.contacts.length > 0" style="font-size: 14px">@微信</span>-->
                      <van-tag color="rgba(22, 139, 246, 0.1)" text-color="#168BF6"
                               style="height: 22px;font-size: 12px;zoom: 0.83" round>{{ item.follow.processId == 0?'待跟进':item.follow.processId == 1?'跟进中':item.follow.processId == 2?'已成交':'无意向' }}
                      </van-tag>
                      <van-tag color="rgba(246, 112, 22, 0.1)" v-if="item.todoStatus == 1" text-color="#F67016"
                               style="height: 22px;font-size: 12px;zoom: 0.83" round>有待办!
                      </van-tag>
                    </div>
                    <div>
                      <div class="line2">
                        最近跟进：{{ !item.follow.content ? '无' : item.follow.content }}
                      </div>
                    </div>
                  </div>
                </div>
                <img class="news" v-if="item.phones.length === 0"
                     src="../../assets/images/customer/phone_nobind.png">
                <img class="news" v-if="item.phones.length > 0" @click="openManyPopup(item,1)"
                     src="../../assets/images/customer/phone_bind.png">
                <img class="news" v-if="item.contacts.length === 0 && item.phones.length > 0"
                     style="margin-left: 10px" @click="openManyPopup(item,2)"
                     src="../../assets/images/customer/wechat_nobind.png">
                <img class="news" v-if="item.contacts.length > 0" @click="openManyWechatPopup(item)"
                     style="margin-left: 10px"
                     src="../../assets/images/customer/wechat_bind.png">
                <img class="news" @click="$refs.addFollow.openShowFollowPopup(item.id,topicId)" style="margin-left: 10px" src="../../assets/images/customer/follow.png">
              </div>
              <div class="bottom">
                <div style="width: 100%;height: 1px;color: #EEEEEE;margin: 0 20px"></div>
                <div class="bottom-list">
                  <div>客户动态：</div>
                  <div style="width: 80%;word-break: break-all;">{{ item.remark ? item.remark : '无' }}</div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab :name="2" title="统计">
        <total-data :id="topicId"></total-data>
      </van-tab>
      <van-tab :name="3" title="运营">
      </van-tab>
      <van-tab :name="4" title="公海">
        <public-sea :topicId="topicId"></public-sea>
      </van-tab>
    </van-tabs>


    <search-filter ref="search" @changeType="changeType" @changeKeyWord="changeKeyWord"></search-filter>

    <add-follow ref="addFollow" @change="reload"></add-follow>

    <van-popup v-model="showMorePhone" position="bottom" :style="{ height: 'auto' }">
      <div class="many-phone" v-for="items in phoneNumbers">
        <div class="title">{{ items.phoneNumber }}</div>
        <a :href="'tel:'+items.phoneNumber">
          <van-button type="info" round size="small" style="width: 100px;">拨打电话</van-button>
        </a>
      </div>
    </van-popup>

    <van-popup v-model="showFriend" position="bottom" :style="{ height: 'auto' }">
      <div class="many-phone" v-for="items in phoneNumbers">
        <div class="title">{{ items.phoneNumber }}</div>
        <van-button type="info" round size="small" style="width: 100px;" @click="navigateToAddCustomers(items.phoneNumber)">添加</van-button>
      </div>
      <div class="many-phone">
        <div class="title">绑定已有企微好友</div>
        <van-button type="info" round size="small" style="width: 100px;" @click="bindFriend">绑定</van-button>
      </div>
      <!--  好友选择-->
      <contact-select ref="contactSelect" />
    </van-popup>

    <van-popup v-model="showMoreWechat" position="bottom" :style="{ height: 'auto' }">
      <div class="many-phone" v-for="items in wechats">
        <div style="display: flex;align-items: center">
          <img :src="items.avatar" style="width: 50px;height: 50px;margin-right: 10px;border-radius: 50%">
          <div class="title">{{items.name}}</div>
        </div>
        <van-button type="info" round size="small" style="width: 100px;" @click="openEnterpriseChat(items.wx_external_userid)">去聊天</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {topicCustomerList} from "@/api/customer";
import {navigateToAddCustomer, openEnterpriseChat, setClipboardData} from "@/utils/wxCodeAuth";
import SearchFilter from './components/searchFilter';
import AddFollow from './components/addFollow';
import {Toast} from "vant";
import TotalData from "@/views/index/components/totalData";
import PublicSea from "@/views/index/components/publicSea";

export default {
  components:{
    PublicSea,
    TotalData,
    SearchFilter,
    AddFollow
  },
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      topicId:'',
      status:'',
      todo:'',
      keyword:'',
      titleActive: 1,
      userList: [],
      phoneNumbers: [],//多个手机号
      wechats:[],
      selectCustomerId: '',//选中的好友
      showMorePhone: false,//多个手机号弹框
      showMoreWechat:false,//去聊天弹框
      showFriend: false,//添加好友弹框
    }
  },
  created() {
    this.topicId = this.$route.query.id
  },
  methods:{
    reload(){
      this.page = 1;
      this.userList = []
      this.onLoad();
    },
    changeKeyWord(keyword){
      this.keyword = keyword;
      this.page = 1;
      this.userList = []
      this.onLoad();
    },
    changeType(id,type){
      if(type == 0){
        //改变状态
        this.status = id;
      }else {
        this.todo = id;
      }
      this.page = 1;
      this.userList = []
      this.onLoad();
    },
    changeStatus(status){
      this.status = status;
      this.page = 1;
      this.userList = []
      this.onLoad();
    },
    // 主题任务列表
    onLoad() {
      let dataLiS = {}
      dataLiS.page = this.page
      dataLiS.perPage = 10
      dataLiS.topicId = this.topicId
      dataLiS.keyword = this.keyword
      dataLiS.status = this.status
      dataLiS.todo = this.todo
      topicCustomerList(dataLiS).then(res => {
        console.log(res.data.data.length)
        this.userList.push(...res.data.data)
        console.log(this.userList)
        if (res.data.data.length < 10) {
          this.finished = true
          this.loading = true
        } else {
          this.page++
          this.finished = false
          this.loading = false
        }
      }).catch((err) => {
        this.loading = false
        this.finished = true
      })
    },
    // 打开多手机号popup
    openManyPopup(item, type) {
      if (type == 1) {
        this.showMorePhone = true;
      } else {
        this.showFriend = true;
      }
      this.phoneNumbers = item.phones;
      this.selectCustomerId = item.id
    },
    // 打开多微信号popup
    openManyWechatPopup(item){
      if(item.contacts.length > 1) {
        this.wechats = item.contacts;
        this.showMoreWechat = true;
      }else {
        this.openEnterpriseChat(item.contacts[0].wx_external_userid);
      }
    },
    //去聊天界面（调用企微接口）
    async openEnterpriseChat(wxUserId) {
      await openEnterpriseChat('',wxUserId,'')
    },
    async navigateToAddCustomers(items) {
      Toast('复制成功')
      await setClipboardData(items)
      await navigateToAddCustomer()
    },
    bindFriend(){
      this.$refs.contactSelect.show(this.selectCustomerId)
    },
  }
}
</script>

<style>
.app {
  background: #F5F5F5;
}
</style>

<style scoped lang="less">
/deep/ .van-nav-bar__content {
  background: #2b63cd;
  color: #fff;
}

/deep/ .van-nav-bar .van-icon {
  color: #fff;
}

/deep/ .van-nav-bar__text {
  color: #fff;
}

/deep/ .van-nav-bar__title {
  color: #fff;
}

.van-nav-bar__right {
  div {
    color: #fff !important;
  }
}

/deep/ .van-tabs--line .van-tabs__wrap {
  height: 37px;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 0px;
}

/deep/ .van-tabs__line{
  bottom: 0px;
}

.item1 {
  width: 96px;
  height: 28px;
  background: #FFFFFF;
  border-radius: 14px 0px 0px 14px;
}

.item2 {
  width: 96px;
  height: 28px;
  background: #FFFFFF;
}

.item3 {
  width: 96px;
  height: 28px;
  background: #FFFFFF;
  border-radius:  0px 14px 14px 0px;
}

.active {
  border: 1px solid #168BF6;
}

.no-active {
  border: 1px solid #DDDDDD;
}

.list {

  .choseItem {
    border: 1px solid #1890ff;

    .bottom {
      .bottom-list {
        background: #fff;
        padding: 10px 12px;
        font-size: 12px;
        display: flex;
        align-items: baseline;
      }
    }
  }

  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 10px 12px;
    border-radius: 7px 7px 0 0;

    .mindel {
      flex: 1;
      margin-left: 10px;
    }

    .avater {
      width: 50px;
      height: 50px;
      border-radius: 5px;
    }

    .item {
      //margin-left: -87px;

      .name {
        font-size: 14px;
        color: #000000;
        font-weight: 500;
        max-width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }


      .line2 {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal;
      }

      div:nth-child(1) {
        h4 {
          font-weight: bold;
          max-width: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span {
          color: #5ec75d;
          margin-left: 2px;
        }
      }

      div:nth-child(2) {
        font-size: 12px;
        color: #6a6a6a;

        div:nth-child(1) {
          //display: flex;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
      }
    }

    .news {
      width: 26px;
      height: 26px;
      background: #e8f0ff;
      border-radius: 50%;
    }
  }
}


.many-phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  .title {
    font-size: 15px;
    font-weight: bold;
    font-family: normal;
  }
}
</style>
