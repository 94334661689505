<template>
  <van-popup v-model="showTodayPopup" position="bottom" round :style="{ height: 'auto' }">
    <div style="position: relative;background: #FFFFFF">
      <van-icon size="20px" style="position: absolute;right: 10px;top:10px" name="close" @click="showTodayPopup = false"/>
      <div class="area">
        <div class="acea-row row-middle" style="margin-bottom: 14px">
          <div class="title-line" style="margin-right: 10px"></div>
          <div class="font-size-14-500 font-color-000">跟进状态</div>
        </div>
        <div style="padding: 0 12px" class="acea-row row-between row-middle">
          <div class="filter-tag font-size-12-400" style="margin-bottom: 12px" @click="changeParam(item.id,0)"
               :class="item.id === form.status?'active-tag font-color-FFF':'no-active-tag font-color-333'"
               v-for="item in statusList">
            {{ item.title }}
          </div>
        </div>
        <div class="acea-row row-middle" style="margin-bottom: 14px">
          <div class="title-line" style="margin-right: 10px"></div>
          <div class="font-size-14-500 font-color-000">待办</div>
        </div>
        <div style="padding: 0 12px;margin-bottom: 15px;" class="acea-row row-middle">
          <div class="filter-tag font-size-12-400" style="margin-right: 10px" @click="changeParam(item.id,1)"
               :class="item.id === form.todo?'active-tag font-color-FFF':'no-active-tag font-color-333'"
               v-for="item in daiList">
            {{ item.title }}
          </div>
        </div>
        <div class="input-area acea-row row-between row-middle">
          <div class="acea-row row-middle">
            <van-icon name="search"  size="22"/>
            <div>
              <van-field style="padding: 0" v-model="form.keyword" placeholder="请输入用户名" />
            </div>
          </div>
          <div class="right-btn acea-row row-center row-middle" @click="blurKeyword">
            <van-icon name="search" color="#FFFFFF" size="22"/>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "waitTodayPopup",
  data() {
    return {
      showTodayPopup: false,
      statusList: [{
        id: '',
        title: '全部'
      }, {
        id: 0,
        title: '待沟通'
      }, {
        id: 1,
        title: '需再次跟进'
      }, {
        id: 2,
        title: '已成交'
      }, {
        id: -1,
        title: '无意向'
      }],
      daiList:[{
        id: '',
        title: '全部'
      }, {
        id: 0,
        title: '无待办'
      }, {
        id: 1,
        title: '有待办'
      }],
      form: {
        status: '',
        todo:'',
        keyword:''
      }
    }
  },
  methods: {
    openShowFilterPopup() {
      this.showTodayPopup = true;
    },
    blurKeyword(){
      this.showTodayPopup =false
      this.$emit("changeKeyWord",this.form.keyword)
    },
    changeParam(id,type){
      if(type == 0){
        //改变状态
        this.form.status = id;
      }else {
        this.form.todo = id;
      }
      this.showTodayPopup = false
      this.$emit("changeType",id,type)
    }
  }
}
</script>

<style scoped lang="less">
.area {
  padding: 20px 12px;

  .input-area {
    width: 100%;
    height: auto;
    padding: 3px 3px 3px 11px;
    background: #FFFFFF;
    border: 1px solid #168BF6;
    border-radius: 18px;

    .right-btn {
      width: 44px;
      height: 30px;
      background: #168BF6;
      border-radius: 15px;
    }
  }
}
</style>
