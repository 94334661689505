<template>
  <van-popup v-model="showTodayPopup" position="center" round :style="{ height: 'auto' }">
        <div style="width: 329px;position: relative;background: #FFFFFF">
          <van-icon size="20px" style="position: absolute;right: 10px;top:10px" name="close" @click="showTodayPopup = false"/>
          <div class="area">
             <div class="title">今日提醒</div>
             <img style="width: 72px;height: 84px" src="../../../assets/images/customer/wait2.png">
             <div class="font-size-14 title1">10条待办事项需要处理</div>
             <div class="font-size-14 title2">20条待办事项已逾期</div>
             <div style="display: flex;justify-content: space-between">
               <van-button style="width: 132px;font-size: 15px;font-weight: 500;" round color="linear-gradient(90deg, #168BF6, #4BA9FF)" text="查看待办"></van-button>
               <van-button style="width: 132px;font-size: 15px;font-weight: 500;" round color="linear-gradient(45deg, #FF7C34, #FFA200)" text="查看逾期"></van-button>
             </div>
          </div>
        </div>
  </van-popup>
</template>

<script>
export default {
  name: "waitTodayPopup",
  data(){
    return{
      showTodayPopup:false
    }
  },
  methods:{
    openShowTodayPopup(){
      this.showTodayPopup = true;
    }
  }
}
</script>

<style scoped lang="less">
    .area {
      padding: 20px 22px 25px;
      text-align: center;

      .title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
        margin-bottom: 20px;
      }

      .font-size-14 {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
      }

      .title1 {
        color: #1981F2;
        margin-top: 17px;
        margin-bottom: 12px;
      }

      .title2 {
        color: #FF7C34;
        margin-bottom: 21px;
      }
    }
</style>
