<template>
  <div class="index">
    <div class="top-part">
      <div class="top-info">
        <div class="time-tips fs24 van-ellipsis">
          <div class="time-tips-box"><span>{{ userDatalt.good }}，{{ userDatalt.name }}</span><!----></div>
        </div>
        <!--        <div class="icon-message">&lt;!&ndash;&ndash;&gt;</div>-->
        <div class="time-detail fs14" style="color: #fff">{{ date }}，{{ week }}</div>
      </div>
      <div class="customer-data">
        <div class="data-item">
          <div class="customer-label"><span class="fs14">我的客户</span></div>
          <div class="customer-value fs24">{{ baseInfo.total }}</div>
        </div>
        <div class="data-item">
          <div class="customer-label"><span class="fs14">昨日新增</span></div>
          <div class="customer-value fs24 center">{{ baseInfo.yesterdayAdd }}</div>
        </div>
        <div class="data-item">
          <div class="customer-label"><span class="fs14">昨日流失</span></div>
          <div class="customer-value fs24 center">{{ baseInfo.yesterdayLoss }}</div>
        </div>
      </div>
    </div>

    <div class="main-content">
      <div style="padding-bottom: 13px;" class="tools-list">
        <div @click="routerPush('/contactStatistic/index')" class="tools-item">
          <div class="img-box"><img
              src="../../assets/images/tongji.png"
              alt=""></div>
          <span class="fs14 tools-name">拉新统计</span></div>
        <div @click="routerPush('/channelCode/index')" class="tools-item">
          <div class="img-box"><img
              src="../../assets/images/huoma.png"
              alt=""></div>
          <span class="fs14 tools-name">渠道活码</span></div>
        <!--        <div @click="routerPush('/contactWelcome/index')" class="tools-item">-->
        <!--          <div class="img-box"><img-->
        <!--              src="../../assets/images/huanyingyu.png"-->
        <!--              alt=""></div>-->
        <!--          <span class="fs14 tools-name">欢迎语</span></div>-->
        <div @click="routerPush('/publicContact/index')" class="tools-item">
          <div class="img-box"><img
              src="../../assets/images/operationalTools/fission.png"
              alt=""></div>
          <span class="fs14 tools-name">客户公海</span></div>
        <div class="tools-item" @click="routerPush('/workMoment/index')">
          <div class="img-box"><img
              src="../../assets/images/pengyouquan.png"
              alt=""></div>
          <span class="fs14 tools-name">历史朋友圈</span></div>
      </div>
    </div>

    <div class="tabs-area">
      <div class="tabs">
        <div class="tabs-item tabs-item1" :class="tabActive == 1?'active':'no-active'" @click="tabActive = 1">
          主题任务
          <div class="active-line" v-if="tabActive == 1"></div>
        </div>
        <div class="tabs-item tabs-item2" :class="tabActive == 2?'active':'no-active'" @click="tabActive = 2">
          我的代办（{{todo.finish.length+todo.overdue.length+todo.wait.length}}）
          <div class="active-line" v-if="tabActive == 2"></div>
<!--          <div class="daiban-tips" @click.stop="$refs.todayWait.openShowTodayPopup()">有新的待办</div>-->
        </div>
      </div>
      <div class="tabs1" v-if="tabActive == 2">
        <div :class="optionChose == item?'tabs1-active':'tabs1-noactive'"  @click="selectType(item)" v-for="(item,index) in optionC">
          {{item}}
          <span v-if="index ==0?todo.finish.length > 0:index ==1?todo.overdue.length > 0:todo.wait.length > 0">({{index ==0?todo.finish.length:index ==1?todo.overdue.length:todo.wait.length}})</span>
          <div class="active1-line" v-if="optionChose == item"></div>
        </div>
        <div class="add-btn" @click="routerPush('/index/create')">
          <van-icon name="add" style="margin-right: 2px" color="#FFFFFF"/>
          新建
        </div>
      </div>
    </div>

    <div v-if="tabActive == 2">
      <div v-if="optionChose == '待开始'">
        <div v-if="todo.wait.length > 0" class="dai-list">
          <div class="acea-row row-middle" style="margin-bottom: 14px">
            <div class="title-line"></div>
            <div class="list-time font-color-333" style="font-size: 14px;margin-left: 10px">
              {{ listDate }}
            </div>
          </div>
          <div v-for="(item, index) in todo.wait" :key="index">
            <dai-item :item="item" :type="1"></dai-item>
          </div>
        </div>
        <div v-else class="data-list" style="background: none">
          <div class="list-time" style="font-size: 14px;margin-bottom: 5px;">
            {{ listDate }}
          </div>
          <div style="background: #fff">
            <van-empty :image="require('../../assets/images/mobiletodo.png')"
                       description="没有待开始的待办~"/>
          </div>
        </div>
      </div>
      <div v-if="optionChose == '已完成'">
        <div v-if="todo.finish.length > 0" class="dai-list">
          <div class="acea-row row-middle" style="margin-bottom: 14px">
            <div class="title-line"></div>
            <div class="list-time" style="font-size: 14px;margin-left: 10px">
              {{ listDate }}
            </div>
          </div>
          <div v-for="(item, index) in todo.finish" :key="index">
            <dai-item :item="item" :type="2"></dai-item>
          </div>
        </div>
        <div v-else class="data-list" style="background: none">
          <div class="list-time" style="font-size: 14px;margin-bottom: 5px;">
            {{ listDate }}
          </div>
          <div style="background: #fff">
            <van-empty :image="require('../../assets/images/mobiletodo.png')"
                       description="没有已完成的待办~"/>
          </div>
        </div>
      </div>
      <div v-if="optionChose == '已逾期'">
        <div v-if="todo.overdue.length > 0" class="dai-list">
          <div class="acea-row row-middle" style="margin-bottom: 14px">
            <div class="title-line"></div>
            <div class="list-time font-color-333" style="font-size: 14px;margin-left: 10px">
              {{ listDate }}
            </div>
          </div>
          <div v-for="(item, index) in todo.overdue" :key="index">
            <dai-item :item="item" :type="3"></dai-item>
          </div>
        </div>
        <div v-else class="data-list" style="background: none">
          <div class="list-time" style="font-size: 14px;margin-bottom: 5px;">
            {{ listDate }}
          </div>
          <div style="background: #fff">
            <van-empty :image="require('../../assets/images/mobiletodo.png')"
                       description="没有已逾期的待办~"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tabActive == 1">
      <div v-if="topicList.length > 0">
        <div v-for="item in topicList">
          <total-item :items="item"></total-item>
        </div>
      </div>
      <div v-else class="data-list" style="background: none">
        <div class="list-time" style="font-size: 14px;margin-bottom: 5px;">
          {{ listDate }}
        </div>
        <div style="background: #fff">
          <van-empty :image="require('../../assets/images/mobiletodo.png')" description="暂时没有需要跟进的客户~"/>
        </div>
      </div>
    </div>
    <div class="loading" v-if="isLoading">
      <a-spin/>
    </div>

    <van-tabbar v-model="active" :safe-area-inset-bottom="true" class="tab">
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/home.png"/>
          <img v-else src="../../assets/images/home-active.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/contact/index" icon="search">
        <span>客户</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/customer-active.png"/>
          <img v-else src="../../assets/images/customer.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/tool/index" icon="setting-o">
        <span>运营工具</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/function-active.png"/>
          <img v-else src="../../assets/images/function.png">
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="setting-o">
        <span>我的</span>
        <template #icon="props">
          <img v-if="props.active" src="../../assets/images/my-active.png"/>
          <img v-else src="../../assets/images/my.png">
        </template>
      </van-tabbar-item>
    </van-tabbar>

    <wait-today-popup ref="todayWait"></wait-today-popup>
    <!--    <wait-popup ref="wait"></wait-popup>-->
<!--    <next-wait-popup ref="wait"></next-wait-popup>-->
  </div>
</template>

<script>
import {workEmployee, updateStatus, userCenter} from "../../api/homePage"
import {contactIndex, customerList, topicall} from "@/api/customer";
import {navigateToAddCustomer, openEnterpriseChat, openUserProfile, setClipboardData} from "@/utils/wxCodeAuth";
import {Toast} from "vant";
import ContactSelect from "@/views/index/components/contactSelect";
import WaitTodayPopup from "@/views/index/components/waitTodayPopup";
import TotalItem from "@/views/index/components/totalItem";
import DaiItem from "@/views/index/components/daiItem";

export default {
  components: {DaiItem, TotalItem, WaitTodayPopup, ContactSelect},
  data() {
    return {
      schedule: 'have-matter-wait',
      // 默认日期
      week: '',
      date: '',
      overTextDate: '',
      // 列表展示日期
      listDate: '',
      // 数据类别加载动画
      isLoading: true,
      // tabbar 切换
      active: 0,
      // tabs切换
      tabActive: 1,
      // 下拉分类
      optionC: ['已完成', "已逾期", '待开始'],
      optionChose: '已完成',
      // 顶部预览数据
      baseInfo: '',
      todo: {
        wait: [],
        finish: [],
        overdue: []
      },
      userDatalt: {
        name: '',
        good: ''
      },
      //主题数据
      topicList: [],
      activeTopic: {
        description: '主题介绍数据',
        processIds:[],
      },//选中的主题数据
    }
  },
  watch: {},
  mounted() {
  },
  methods: {
    selectType(e) {
      this.optionChose = e
      switch (e) {
        case '已逾期':
          this.schedule = 'have-matter-over'
          break;
        case '已完成':
          this.schedule = 'have-matter-finsh'
          break;
        case '待开始':
          this.schedule = 'have-matter-wait'
          break;
      }

      // this.$refs.content.formatter.haveMatter()
    },
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
    // 我的待办数据
    getNowTime(e) {
      this.isLoading = true
      workEmployee({day: e}).then(res => {
        this.baseInfo = res.data.baseInfo
        this.todo = res.data.todo
        this.isLoading = false
      }).catch((err) => {
        this.isLoading = false
      })
    },
    topicall() {
      topicall().then(res => {
        this.topicList = res.data
      })
    },
    // 获取当前日期
    getWeekDate() {
      const now = new Date();
      const day = now.getDay();
      const weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
      const year = new Date().getFullYear();
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      this.week = weeks[day];
      this.date = mm + "月" + dd + "日"
      this.dateDetail = {
        year: year,
        month: mm,
        day: dd
      }
      this.listDate = '今天 · ' + mm + "月" + dd + "日" + "  " + weeks[day]

    },
    // 根据日期切换
    dataSelect(e) {
      const year = e.getFullYear();
      const month = (e.getMonth() + 1) < 10 ? '0' + (e.getMonth() + 1) : (e.getMonth() + 1);    //js从0开始取
      const date1 = (e.getDate()) < 10 ? '0' + (e.getDate()) : (e.getDate());
      const day = e.getDay();
      const weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
      if (date1 == this.dateDetail.day) {
        this.listDate = '今天 · ' + month + "月" + date1 + "日" + "   " + weeks[day]
      } else if (year == this.dateDetail.year) {
        this.listDate = month + "月" + date1 + "日" + "   " + weeks[day]
      } else {
        this.listDate = year + '年' + month + "月" + date1 + "日" + "   " + weeks[day]
      }
      this.overTextDate = year + "-" + month + "-" + date1
      this.getNowTime(year + "-" + month + "-" + date1)
    },
    // 日期格式化
    formatter(day, css) {
      const year = day.date.getFullYear();
      const month = day.date.getMonth() + 1;
      const date = day.date.getDate();
      ('日期格式化')
      const details = this.dateDetail

      if (year === details.year && month === details.month) {
        if (date === 1) {
          day.className = this.schedule;
        } else if (date === 4) {
          day.className = this.schedule;
        } else if (date === details.day) {
          day.className = 'nowDate';
        }
      }
      return day;
    },
    // 完成任务
    overDate(id) {
      updateStatus({id: id}).then(res => {
        this.getNowTime(this.overTextDate)
      })
    },
    details(i) {
      this.$router.push({
        path: "/contact/detail",
        query: {
          contactId: i.customerId
        }
      })
    },
    userDetails() {
      userCenter().then(res => {
        this.userDatalt = res.data.baseInfo
        const now = new Date()
        const hour = now.getHours()

        if (hour < 6) {
          this.userDatalt.good = "凌晨好"
        } else if (hour < 9) {
          this.userDatalt.good = "早上好"
        } else if (hour < 12) {
          this.userDatalt.good = "上午好"
        } else if (hour < 14) {
          this.userDatalt.good = "中午好"
        } else if (hour < 17) {
          this.userDatalt.good = "下午好"
        } else if (hour < 19) {
          this.userDatalt.good = "傍晚好"
        } else if (hour < 22) {
          this.userDatalt.good = "晚上好"
        } else {
          this.userDatalt.good = "夜里好"
        }
      })
    },
    detailsee(i) {

    }
  },
  created() {
    // 获取日期
    this.getWeekDate()
    // 获取数据
    this.getNowTime()
    // user信息
    this.userDetails()
    // 获取主题信息
    this.topicall()
  }

}
</script>

<style lang="less" scoped>
@wait: #45c400;
@over: #c8cad0;
@finsh: red;
/deep/ .van-tabbar {
  box-sizing: content-box !important;
}

/deep/ .van-tabs__line {
  bottom: 12px;
  margin-top: 0;
}

/deep/ .van-tab--active {
  font-weight: bold;
}

/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}

/deep/ .have-matter-wait {
  &:before {
    background: @wait;
    position: absolute;
    z-index: 88;
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 1px;
    //background: #c8cad0;
  }
}

/deep/ .have-matter-over {
  &:before {
    background: @finsh;
    position: absolute;
    z-index: 88;
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 1px;
    //background: #c8cad0;
  }
}

/deep/ .have-matter-finsh {
  &:before {
    background: @over;
    position: absolute;
    z-index: 88;
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 1px;
    //background: #c8cad0;
  }
}

/deep/ .hide-icon .van-radio__icon {
  visibility: hidden;
}

/deep/ .hide-icon .van-radio__label {
  width: 126px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.van-radio {
  width: 139px;
}


.title-overflow {
  width: 100%;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  margin-top: 3px;

  /deep/ .van-radio__label {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.index {
  font-size: 14px;
  min-height: 100%;
  background-color: #f2f3f7;
  padding-bottom: 70px;

  .fs14 {
    opacity: .7;
    font-weight: 400;
    font-size: 14px;
  }

  .fs16 {
    font-size: 12px;
  }

  .fs12 {
    font-size: 12px;
  }

  .fs24 {
    font-size: 24px;
  }

  .tab {
    z-index: 3;
    img {
      width: 22px;
      height: 22px;
    }
  }

  .iconS {
    transform: rotate(180deg);
    color: #1890ff !important;
  }

  .top-part {
    height: 310px;
    background: linear-gradient(180deg, #3974c7, #0a42c3 55%, #2b63cd);
    padding: 16px 12px 0;
    position: relative;

    .top-info {
      padding: 0 12px 0 8px;

      .time-tips-box {
        display: flex;
        align-items: center;
      }

      .time-tips {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 33px;
        font-weight: 500;
        color: #fff;
        width: 252.5px;
      }

      .van-ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .icon-message {
        position: absolute;
        top: 25px;
        right: 20px;
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url(../../assets/images/news.png) no-repeat;
        background-size: 100% 100%;
      }

      .time-detail {
        color: #c2d7ff;
        margin-top: 4px;
        line-height: 20px;
      }
    }

    .customer-data {
      cursor: pointer;
      height: 104px;
      background: url(../../assets/images/background.png) no-repeat 50%/contain;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      padding: 17px 30px 29px 30px;
      position: relative;
      top: 24px;
      color: #fff !important;

      .data-item {
        text-align: center;

        .customer-label {
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 20px;
          position: relative;
        }

        .customer-value {
          font-family: DINAlternateBold;
          font-weight: 700;
          color: #fff;
          line-height: 36px;
        }
      }
    }
  }

  .loading {
    width: 100%;
    height: 228.94px;
    /* background: #fff; */
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .main-content {
    margin-top: -131px;
    z-index: 1;
    position: relative;
    background: #FFFFFF;
    border-radius: 12px 12px 0px 0px;

    .tools-list {
      display: flex;
      flex-direction: row;
      padding-top: 38px;

      .tools-item {
        flex: 1;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img-box {
          width: 44px;
          height: 44px;
          margin-bottom: 22px;

          img {
            width: 44px;
            height: 44px;
          }
        }

        .tools-name {
          color: #333333;
          font-weight: 400;
          font-size: 14px;
          line-height: 32px;
        }
      }
    }
  }


  .tabs-area {
    margin-top: 15px;

    .tabs {
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;

      .tabs-item {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFang SC;
        border-radius: 6px 6px 4px 4px;
      }

      .tabs-item1 {
        position: absolute;
        left: 0;
      }

      .tabs-item2 {
        position: absolute;
        right: 0;
      }

      .no-active {
        width: 193px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        background-image: url("../../assets/images/customer/noselect.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .active {
        width: 193px;
        background: #FFFFFF;
        z-index: 99;
        font-size: 15px;
        font-weight: bold;
        color: #168BF6;
      }

      .active-line {
        position: absolute;
        bottom: 0;
        width: 20px;
        height: 3px;
        background: linear-gradient(90deg, #168BF6, #3DA2FF);
        border-radius: 2px 2px 1px 1px;
      }

      .daiban-tips {
        position: absolute;
        right: 10px;
        top: 10px;
        width: auto;
        padding: 0 7px;
        height: 15px;
        text-align: center;
        background: linear-gradient(90deg, #FF7C34, #FFA200);
        border-radius: 8px;
        font-size: 12px;
        zoom: 0.83;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .tabs1 {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 35px;
      padding: 0 12px;
      background: #FFFFFF;

      .tabs1-active {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
        display: flex;
        justify-content: center;
        margin-right: 30px;
      }

      .tabs1-noactive {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-right: 30px;
      }

      .active1-line {
        position: absolute;
        bottom: 0;
        width: 21px;
        height: 2px;
        background: #168BF6;
        border-radius: 1px 1px 1px 1px;
      }

      .add-btn {
        width: 60px;
        height: 22px;
        text-align: center;
        color: #FFFFFF;
        background: linear-gradient(90deg, #FF7C34, #FFAF23);
        border-radius: 11px;
      }
    }
  }

  .dai-list {
    width: 351px;
    height: auto;
    margin: 10px auto;
    padding: 15px 12px;
    background: #FFFFFF;
    border-radius: 6px;
  }


  .data-list {
    margin: 7px 16px;
    color: #767676;
    font-weight: 500;
    //background-color: #fff;
  }

  .title-container {
    background: #FFFFFF;
    padding: 0px 12px;

    .title-pointer {
      width: 5px;
      height: 5px;
      background: #2E9AFC;
      border-radius: 50%;
      margin-top: 10px;
      margin-right: 5px
    }

    .title {
      max-width: 205px;
    }
  }
}


</style>
