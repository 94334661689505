<template>
   <div style="padding:16px 12px">
     <div class="acea-row row-middle" style="margin-bottom: 18px">
        <div class="title-line" style="margin-right:12px"></div>
        <div class="font-size-14-500 font-color-000">客户总量：{{totalData.users.all}}位</div>
     </div>
     <div class="total-area">
       <div class="acea-row row-middle row-between" style="padding: 0 36px 0 4px">
         <div class="acea-row row-middle" v-for="item in 2">
            <img style="width: 43px;height: 43px" :src="item == 1?require('@/assets/images/customer/wait.png'):require('@/assets/images/customer/success.png')">
            <div  style="margin-left: 13px">
              <div class="font-size-18-bold font-color-000">{{item ==1?totalData.users.wait_talk:totalData.users.all_success}}</div>
              <div class="font-size-14-400 font-color-666">{{item ==1?'待沟通':'已成交'}}</div>
            </div>
         </div>
       </div>
       <div class="line" style="margin: 20px 0"></div>
       <div class="acea-row row-middle row-between" style="padding: 0 36px 0 4px">
         <div class="acea-row row-middle" v-for="item in 2">
           <img style="width: 43px;height: 43px" :src="item == 1?require('@/assets/images/customer/talking.png'):require('@/assets/images/customer/fail.png')">
           <div style="margin-left: 13px">
             <div class="font-size-18-bold font-color-000">{{item ==1?totalData.users.talking:totalData.users.failed}}</div>
             <div class="font-size-14-400 font-color-666">{{item ==1?'跟进中':'无意向'}}</div>
           </div>
         </div>
       </div>
     </div>
   </div>
</template>

<script>
import {topicDetail} from '@/api/customer'
export default {
  name: "totalData",
  props:['id'],
  data(){
    return{
      totalData:{}
    }
  },
  created() {
    this.getTotal()
  },
  methods:{
    getTotal(){
      topicDetail({
        id: this.id
      }).then(res => {
         this.totalData = res.data;
      })
    }
  }
}
</script>

<style scoped lang="less">
  .total-area {
    width: 100%;
    height: auto;
    padding: 24px 12px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(110,115,126,0.1);
    border-radius: 6px;
  }
</style>
