<template>
  <van-popup v-model="showTodayPopup" position="bottom" round :style="{ height: 'auto' }">
    <div style="position: relative;background: #FFFFFF">
      <van-icon size="20px" style="position: absolute;right: 10px;top:10px" name="close" @click="showTodayPopup = false"/>
      <div class="area">
        <div class="acea-row row-middle" style="margin-bottom: 14px">
          <div class="title-line" style="margin-right: 10px"></div>
          <div class="font-size-14-500 font-color-000">跟进结果</div>
        </div>
        <div style="padding: 0 12px" class="acea-row row-between row-middle">
          <div class="filter-tag font-size-12-400" style="margin-bottom: 12px" @click="changeParam(item.id)"
               :class="item.id === form.process?'active-tag font-color-FFF':'no-active-tag font-color-333'"
               v-for="item in statusList">
            {{ item.title }}
          </div>
        </div>
        <div class="acea-row row-middle" style="margin-bottom: 14px">
          <div class="title-line" style="margin-right: 10px"></div>
          <div class="font-size-14-500 font-color-000">跟进内容</div>
        </div>
        <div style="margin-bottom: 18px">
          <van-field
              style="background: #F5F5F5"
              v-model="form.content"
              rows="1"
              type="textarea"
              placeholder="请输入要添加的跟进信息吧，添加成功后将出现在客户动态列表里~"
          />
        </div>
        <div class="acea-row row-middle row-between" style="margin-bottom: 14px">
          <div class="acea-row row-middle">
            <div class="title-line" style="margin-right: 10px"></div>
            <div class="font-size-14-500 font-color-000">下次跟进提醒</div>
          </div>
          <van-switch size="16" v-model="form.createTodo" :active-value="1" :inactive-value="0"/>
        </div>
        <div class="next-area acea-row row-middle row-between" style="margin-bottom: 15px" v-if="form.createTodo == 1"
             @click="showChange = true">
          <div class="font-size-14-400 font-color-666">提醒时间</div>
          <div class="acea-row row-middle">
            <div class="font-size-14-500 font-color-333">{{ !form.timeType ? '自定义时间' : form.todoTime + '小时后' }}</div>
            <van-icon name="arrow" size="11"/>
          </div>
        </div>
        <div class="next-area acea-row row-middle row-between" style="margin-bottom: 15px"
             v-if="form.createTodo == 1 && !form.timeType" @click="$refs.time.$show()">
          <div class="font-size-14-400 font-color-666">自定义时间</div>
          <div class="acea-row row-middle">
            <div class="font-size-14-500 font-color-333">{{ form.customTime }}</div>
            <van-icon name="arrow" size="11"/>
          </div>
        </div>
        <div v-if="form.createTodo == 1" style="margin-bottom: 20px">
          <van-field
              style="background: #F5F5F5"
              v-model="form.todoContent"
              rows="1"
              type="textarea"
              placeholder="请输入待办内容，将在首页提醒您~~"
          />
        </div>
        <div>
          <van-button @click="addProcessEvent" color="#168BF6" round style="width: 100%;height: 49px;font-size: 17px">
            保存
          </van-button>
        </div>
      </div>
    </div>
    <van-action-sheet
        v-model="showChange"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        @select="selectItem"
        @cancel="showChange = false"
    />
    <addTime ref="time" @change="time"></addTime>
  </van-popup>
</template>

<script>
import addTime from "@/views/index/components/addTime";
import {Toast} from "vant";
import {ContactFollowApi} from "@/api/contactFollow";

export default {
  name: "waitTodayPopup",
  components: {
    addTime
  },
  data() {
    return {
      showChange: false,
      showTodayPopup: false,
      statusList: [{
        id: 1,
        title: '需再次跟进'
      }, {
        id: 2,
        title: '已成交'
      }, {
        id: -1,
        title: '无意向'
      }],
      actions: [{name: '1小时后'}, {name: '3小时后'}, {name: '6小时后'}, {name: '12小时后'}, {name: '24小时后'}, {name: '自定义'}],
      form: {
        topicId: '',
        customerId: '',
        process: '',
        content: '',
        createTodo: 0,
        timeType: 1,
        todoTime: 1,
        customTime: '',
        todoContent: ''
      }
    }
  },
  methods: {
    openShowFollowPopup(id,topicId) {
      this.form.customerId = id;
      this.form.topicId = topicId
      this.showTodayPopup = true;
    },
    selectItem(action, index) {
      if (index == 0) {
        this.form.todoTime = 1
        this.form.timeType = 1
      } else if (index == 1) {
        this.form.todoTime = 3
        this.form.timeType = 1
      } else if (index == 2) {
        this.form.todoTime = 6
        this.form.timeType = 1
      } else if (index == 3) {
        this.form.todoTime = 12
        this.form.timeType = 1
      } else if (index == 4) {
        this.form.todoTime = 24
        this.form.timeType = 1
      } else {
        this.form.todoTime = -1
        this.form.timeType = ''
      }
    },
    // 选择时间
    time(e) {
      this.form.customTime = e.date + " " + e.time
    },
    changeParam(id) {
      //改变状态
      this.form.process = id;
    },
    removeData() {
      this.form = {
        topicId: '',
        customerId: '',
        process: '',
        content: '',
        createTodo: 0,
        timeType: 1,
        todoTime: 1,
        customTime: '',
        todoContent: ''
      }
    },
    addProcessEvent() {
      if (!this.form.topicId) {
        Toast('主题不能为空')
        return false
      }
      if (!this.form.process) {
        Toast('客户状态不能为空')
        return false
      }
      if (this.form.content == '') {
        Toast('跟进内容不能为空')
        return false
      }
      if (this.form.createTodo == 1) {
        if (this.form.todoTime == -1) {
          if (!this.form.customTime) {
            Toast('请填写自定义时间')
            return
          }
        }
        if (!this.form.todoContent) {
          Toast('下次联系内容不能为空')
          return false
        }
      }
      var param = {
        content: this.form.content,
        process: this.form.process,
        topicId: this.form.topicId,
        customerId: this.form.customerId,
        createTodo: this.form.createTodo,
        todoContent: this.form.todoContent
      };
      if (this.form.todoTime == -1) {
        param.todoTime = this.form.customTime;
        param.timeType = '';
      } else {
        param.todoTime = this.form.todoTime;
        param.timeType = 1;
      }
      ContactFollowApi.store(param).then((res) => {
        Toast('添加成功')
        this.removeData()
        this.showTodayPopup = false
        this.$emit('change', true)
      })
    },
  }
}
</script>

<style scoped lang="less">
.area {
  padding: 20px 12px;

  .next-area {
    height: 40px;
    padding: 0 13px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
  }
}
</style>
