<template>
    <div style="width: 100%;padding: 0 12px;background: #FFFFFF" @click="$router.push('/index/title?id='+items.id)">
      <div style="padding: 20px 0" class="acea-row row-between row-middle">
         <div class="acea-row row-top">
           <div class="title-pointer"></div>
           <div>
             <div class="font-size-14-500 font-color-000 line1" style="max-width: 200px">{{items.title}}</div>
             <div class="font-size-12-400 font-color-666 line2"  style="max-width: 200px">{{items.description}}</div>
           </div>
         </div>
         <div class="acea-row row-middle">
           <div style="text-align: center;margin-right: 14px">
             <div class="font-size-15-bold font-color-000">{{items.wait_talk}}</div>
             <div class="font-size-12-400 font-color-666">待沟通</div>
           </div>
           <div style="text-align: center;margin-right: 14px">
             <div class="font-size-15-bold font-color-000">{{items.talking}}</div>
             <div class="font-size-12-400 font-color-666">跟进中</div>
           </div>
           <van-icon name="arrow" />
         </div>
      </div>
      <div style="width: 100%;height: 1px;background: #EEEEEE"></div>
    </div>
</template>

<script>
export default {
  name: "totalItem",
  props:['items'],
  mounted() {
    console.log(this.items);
  }
}
</script>

<style scoped lang="less">
  .title-pointer {
    width: 5px;
    height: 5px;
    margin-top: 10px;
    margin-right: 7px;
    background: #2E9AFC;
    border-radius: 50%;
  }
</style>